import React, { useContext } from 'react';
import Layout from '../components/global/layout';
import { makeStyles } from '@material-ui/core/styles';
import SEO from '../components/seo';
import PageBanner from '../components/layouts/page-banner';
import ContactForm from '../components/layouts/contact-form';
import VerticalPadding from '../components/global/vertical-padding';
import { Box, Container, Grid, Typography } from '@material-ui/core';
import LightMode from '../components/global/light-mode';
import ThemeContext from '../contexts/theme-context';
import ContactDetail from '../components/layouts/contact-detail';

const useContactStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    [theme.breakpoints.up('md')]: {
      '&::after': {
        backgroundColor: '#fff',
        content: '""',
        display: 'block',
        position: 'absolute',
        right: '0vw',
        top: 0,
        width: '33.33vw',
        height: '100%',
        zIndex: 1,
      },
    },
  },
  genericBgrnd: {
    paddingRight: 0,
    [theme.breakpoints.down('md')]: {
      padding: 0,
    },
  },
  gridWhite: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    backgroundColor: '#fff',
    zIndex: 2,
  },
  FormColumn: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    maxWidth: 820,
    [theme.breakpoints.up('lg')]: {
      paddingRight: 100,
      margin: '0 auto',
    },
  },
  VerticalPadding: {
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(20),
      paddingBottom: theme.spacing(20),
      height: '100%',
    },
  },
}));

const Contact: React.FC = () => {
  const { appBarVisible } = useContext(ThemeContext);
  const ContactStyles = useContactStyles(appBarVisible);

  return (
    <Layout>
      <SEO
        title="Contact Us"
        description="Marvel &amp; Snap is an experienced web services and operations team located in Ottawa, Ontario. Contact us with your web needs today."
      />
      <PageBanner
        maxWidth={800}
        heading="we’re ready when you are"
        imageSrc="contact-banner.jpg"
      />
      <Box bgcolor="primary.main">
        <VerticalPadding>
          <Container>
            <Grid container justify="center">
              <Grid
                item
                xs={12}
                md={11}
                lg={10}
                xl={9}
                style={{ textAlign: 'center' }}
              >
                <Typography
                  variant="h2"
                  style={{
                    color: 'white',
                    fontFamily: 'Visby CF',
                    textTransform: 'unset',
                    marginBottom: 45,
                    fontSize: 32,
                  }}
                >
                  If you’re ready to get going on a web operations package, or
                  if you have questions, we’re more than happy to continue the
                  conversation with you.
                </Typography>
                <Typography
                  variant="h2"
                  style={{
                    color: 'white',
                    fontFamily: 'Visby CF',
                    textTransform: 'unset',
                    fontSize: 32,
                  }}
                >
                  Fill out the form below and someone from our team will get
                  back to you shortly.
                </Typography>
              </Grid>
            </Grid>
          </Container>
        </VerticalPadding>
      </Box>

      <LightMode>
        <Box className={ContactStyles.root}>
          <Container className={ContactStyles.genericBgrnd}>
            <Grid container direction="row" justify="center">
              <Grid item xs={12} md={8} lg={7}>
                <VerticalPadding className={ContactStyles.VerticalPadding}>
                  <Box className={ContactStyles.FormColumn}>
                    <Typography variant="h2" style={{ marginBottom: 30 }}>
                      contact us
                    </Typography>
                    <ContactForm />
                  </Box>
                </VerticalPadding>
              </Grid>
              <Grid item xs={12} md={4} className={ContactStyles.gridWhite}>
                <VerticalPadding className={ContactStyles.VerticalPadding}>
                  <ContactDetail />
                </VerticalPadding>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </LightMode>
    </Layout>
  );
};

export default Contact;
