import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Link,
  Typography,
  TextField,
  CircularProgress,
} from '@material-ui/core';
import Button from '../mui/button';
import FieldsWrapper from '../form/fields-wrapper';
import { sendMail } from '../../services/mail';
import { Controller, useForm } from 'react-hook-form';

const useFormStyles = makeStyles((theme) => ({
  error: {
    lineHeight: '15px',
    color: theme.palette.error.main,
  },
  wrapper: {
    display: 'inline-block',
    marginTop: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: theme.palette.violet.dark,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const ContactForm: React.FC = () => {
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [submitFailure, setSubmitFailure] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const FormStyles = useFormStyles();
  let formContent: any;

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data: any) => {
    const from = data.email;
    const subject = 'New contact form submission';

    const bodyParts: string[] = [
      `<p><strong>From:</strong> ${data.firstName} ${data.lastName} <${data.email}></p>`,
    ];

    if (data.message.length) {
      bodyParts.push(`<p><strong>Message:</strong> ${data.message}</p>`);
    }

    const message = bodyParts.join('');

    setSubmitting(true);

    sendMail('info@marvelandsnap.com', from, subject, message)
      .then(() => {
        setSubmitSuccess(true);
        setSubmitting(false);
      })
      .catch(() => {
        setSubmitFailure(false);
      });
    await new Promise((r) => setTimeout(r, 500));
  };

  if (submitSuccess) {
    formContent = (
      <Box>
        <Typography
          variant="subtitle1"
          component="p"
          color="textSecondary"
          gutterBottom={true}
        >
          Thank you for getting in touch!
        </Typography>
        <Typography variant="body2" gutterBottom={true}>
          We will look over your message and get back in touch with you very
          shortly.
        </Typography>
        <Typography variant="body2">Have a great day!</Typography>
      </Box>
    );
  } else if (submitFailure) {
    formContent = (
      <Box>
        <Typography
          variant="subtitle1"
          component="p"
          color="textSecondary"
          gutterBottom={true}
        >
          Something went wrong!
        </Typography>
        <Typography variant="body2">
          Your request could not be completed. Please contact{' '}
          <Link href="mailto:support@marvelandsnap.com">
            support@marvelandsnap.com
          </Link>{' '}
          for assistance.
        </Typography>
      </Box>
    );
  } else {
    formContent = (
      <form onSubmit={handleSubmit(onSubmit)}>
        <FieldsWrapper mode="grey">
          <Controller
            control={control}
            name="firstName"
            render={({ field: { onChange, ref } }) => (
              <TextField
                id="firstName"
                label="first name"
                variant="outlined"
                onChange={onChange}
                fullWidth
                inputRef={ref}
              />
            )}
            rules={{ required: true }}
          />

          <Controller
            control={control}
            name="lastName"
            render={({ field: { onChange, ref } }) => (
              <TextField
                id="lastName"
                label="last name"
                variant="outlined"
                onChange={onChange}
                fullWidth
                inputRef={ref}
              />
            )}
            rules={{ required: true }}
          />

          <Controller
            control={control}
            name="email"
            render={({ field: { onChange, ref } }) => (
              <TextField
                id="email"
                label="email"
                variant="outlined"
                onChange={onChange}
                fullWidth
                inputRef={ref}
              />
            )}
            rules={{
              required: true,
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                message: 'invalid email address',
              },
            }}
          />

          <Controller
            control={control}
            name="message"
            render={({ field: { onChange, ref } }) => (
              <TextField
                id="message"
                label="your message…"
                variant="outlined"
                onChange={onChange}
                fullWidth
                multiline={true}
                rows={15}
                rowsMax={30}
                inputRef={ref}
              />
            )}
            rules={{ required: true }}
          />

          {errors.firstName || errors.lastName || errors.message ? (
            <Typography
              style={{ marginBottom: 20 }}
              className={FormStyles.error}
            >
              All fields are mandatory
            </Typography>
          ) : errors.email ? (
            <Typography
              style={{ marginBottom: 20 }}
              className={FormStyles.error}
            >
              Please enter a valid email address
            </Typography>
          ) : null}
        </FieldsWrapper>

        <div className={FormStyles.wrapper}>
          <Button
            size="large"
            color="primary"
            type="submit"
            variant="contained"
            disabled={submitting}
            disableElevation
          >
            Submit
          </Button>
          {submitting && (
            <CircularProgress size={24} className={FormStyles.buttonProgress} />
          )}
        </div>
      </form>
    );
  }

  return <Box>{formContent}</Box>;
};

export default ContactForm;
