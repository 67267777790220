import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography, Link } from '@material-ui/core';
import ThemeContext from '../../contexts/theme-context';

const useStyles = makeStyles((theme) => ({
  root: (appBarVisible) => ({
    top: theme.spacing(3) + (appBarVisible ? 150 : 0),
    transition: `top ${theme.transitions.duration.enteringScreen}ms ${theme.transitions.easing.easeOut}`,
    [theme.breakpoints.up('md')]: {
      position: 'sticky',
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: 300,
      margin: '0 auto',
    },
  }),
  heading: {
    marginBottom: 50,
  },
  lead: {
    fontSize: 24,
    color: '#9998A5',
    marginBottom: 50,
    display: 'inline-block',
  },
  text: {
    fontSize: 18,
    color: theme.palette.grey[700],
    marginBottom: 50,
    display: 'block',
  },
}));

const ContactDetail: React.FC = () => {
  const { appBarVisible } = useContext(ThemeContext);

  const classes = useStyles(appBarVisible);

  return (
    <Box className={classes.root} color="neutral.dark">
      <Typography variant="h3" className={classes.heading}>
        give us a call
      </Typography>
      <Typography variant="body2">
        <Link href="tel:+16132220610" className={classes.lead}>
          613-222-0610
        </Link>
      </Typography>
      <Typography variant="body2" className={classes.text}>
        1000 Innovation Drive
        <br /> Suite 500
        <br /> Kanata, ON, Canada
        <br /> K2X 3E7
      </Typography>
      <Typography variant="body2">
        <Link
          href="mailto:info@marvelandsnap.com"
          className={classes.text}
          style={{ marginBottom: 0, display: 'inline-block' }}
        >
          info@marvelandsnap.com
        </Link>
      </Typography>
    </Box>
  );
};

export default ContactDetail;
