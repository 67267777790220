import React from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import theme from '../../lib/theme';
import { fade } from '@material-ui/core/styles/colorManipulator';

const useStyles = makeStyles(() => ({
  fieldWrapper: {
    marginBottom: 40,
    '& [class*="MuiInputBase-formControl-"]': {
      marginBottom: 20,
    },
    '& [class*="MuiInputLabel-root-"], input': {
      fontSize: 15,
      fontFamily: 'Visby CF, sans-serif',
      fontWeight: 700,
      padding: '0 10px',
      marginBottom: 0,
      lineHeight: '9px',
    },
    '& textarea': {
      fontSize: 15,
      fontFamily: 'Visby CF, sans-serif',
      fontWeight: 700,
      padding: '0 10px',
      marginBottom: 0,
      lineHeight: '23px',
    },
    '& [class*="MuiOutlinedInput-notchedOutline-"], & .Mui-focused': {
      borderRadius: 0,
      borderWidth: '2px !important',
    },
    '& [class*="MuiInputBase-input-"]': {
      padding: '16px 26px',
    },
    '& [class*="MuiOutlinedInput-inputMultiline-"]': {
      padding: 10,
    },
  },
  white: {
    '& [class*="MuiOutlinedInput-root-"]:hover [class*="MuiOutlinedInput-notchedOutline-"]':
      {
        borderColor: fade(theme.palette.common.white, 0.5),
      },
    '& .Mui-focused [class*="MuiOutlinedInput-notchedOutline-"]': {
      borderColor: fade(theme.palette.common.white, 0.5),
    },
    '& [class*="MuiInputLabel-root-"], input, textarea': {
      color: theme.palette.common.white,
    },
  },
  grey: {
    '& [class*="MuiOutlinedInput-root-"]:hover [class*="MuiOutlinedInput-notchedOutline-"]':
      {
        borderColor: theme.palette.neutral.dark,
      },
    '& .Mui-focused [class*="MuiOutlinedInput-notchedOutline-"]': {
      borderColor: theme.palette.neutral.dark,
    },
    '& [class*="MuiOutlinedInput-notchedOutline-"]': {
      borderColor: theme.palette.grey[400],
    },
    '& [class*="MuiInputLabel-root-"], input, textarea': {
      color: theme.palette.grey[700],
    },
  },
}));

interface FieldsWrapperProps {
  mode?: string;
  classes?: string;
  childeren?: React.ReactNode;
}

const FieldsWrapper: React.FC<FieldsWrapperProps> = ({
  mode,
  classes,
  children,
}) => {
  const wrapperStyles = useStyles();
  const className = clsx(
    wrapperStyles.fieldWrapper,
    mode && wrapperStyles[mode],
    classes
  );

  return <Box className={className}>{children}</Box>;
};

export default FieldsWrapper;
